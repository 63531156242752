<template>
  <div class="editar-franquicia">
    <vs-alert
      color="danger"
      title="Ally Not Found"
      :active.sync="franquiciaNotFound"
    >
      <span
        >Ally record with id:
        {{ this.$route.params.franquiciaId.franquiciaId }} not found.
      </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'terceros-franquicias' }"
          class="text-inherit underline"
          >All Franquicias</router-link
        >
      </span>
    </vs-alert>
    <vx-card>
      <div
        v-if="
          franquicia.attention_schedule &&
          franquicia.attention_schedule.length > 0
        "
      >
        <ValidationObserver
          ref="validateFormFranchiseUpdate"
          v-slot="{ invalid }"
        >
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Razón social"
              >
                <vs-input
                  class="w-full"
                  label="Razón social"
                  placeholder="Razón social"
                  v-model="franquicia.name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="numeric|required"
                name="NIT"
              >
                <vs-input
                  class="w-full"
                  label="NIT"
                  placeholder="NIT"
                  v-model="franquicia.nit"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Contacto"
              >
                <vs-input
                  class="w-full"
                  label="Contacto"
                  placeholder="Contacto"
                  v-model="franquicia.contact_name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="phone|required"
                name="Teléfono"
              >
                <vs-input
                  class="w-full"
                  label="Teléfono"
                  placeholder="Teléfono"
                  v-model="franquicia.phone"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Tipo de negocio"
              >
                <vs-select
                  class="w-full"
                  label="Tipo de negocio"
                  placeholder="Tipo de negocio"
                  v-model="franquicia.commerce_type"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in typeCommerce"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Dirección"
              >
                <vs-input
                  class="w-full"
                  label="Dirección"
                  placeholder="Dirección"
                  v-model="franquicia.address"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Horario de atención"
              >
                <vs-select
                  class="w-full"
                  label="Horario de atención"
                  multiple
                  v-model="franquicia.attention_schedule"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in ListSchedules"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Medio de entrega"
              >
                <vs-select
                  class="w-full"
                  label="Medio de entrega"
                  multiple
                  v-model="franquicia.delivery_media"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.name"
                    :text="item.alias"
                    v-for="(item, index) in vehicleTypes"
                  />
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full flex justify-end">
              <vs-button
                class="mr-3 mb-3"
                @click="updateFranchise"
                :disabled="invalid"
                >Guardar</vs-button
              >
              <vs-button type="border" class="mb-3" @click="$router.go(-1)"
                >Descarcatar</vs-button
              >
            </div>
          </div>
        </ValidationObserver>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { FRANCHISE, VEHICLE_TYPES, FRANCHISES } from '@/graphql/queries';
import { UPDATE_FRANCHISE } from '@/graphql/mutations';
import _ from 'lodash';
export default {
  data() {
    return {
      franquicia: {},
      franquiciaNotFound: false,
      typeCommerce: [
        { text: 'Negocio', value: 'business' },
        { text: 'Tienda', value: 'online_store' },
        { text: 'Pastelería', value: 'restaurant_pastry' },
      ],
      ListSchedules: [
        { text: 'Mañana', value: 'Mañana' },
        { text: 'Medio día', value: 'Medio día' },
        { text: 'Tarde', value: 'Tarde' },
        { text: 'Noche', value: 'Noche' },
      ],
    };
  },

  apollo: {
    franchise: {
      query: FRANCHISE,
      variables() {
        return {
          id: this.$route.params.franquiciaId, // Works here!
        };
      },
      result({ data }) {
        if (!data?.franchise) return;
        this.franquicia = data.franchise;
      },
    },
    vehicleTypes: {
      query: VEHICLE_TYPES,
    },
  },
  methods: {
    updateFranchise() {
      this.franquicia = _.omit(this.franquicia, ['__typename']);
      this.$apollo
        .mutate({
          mutation: UPDATE_FRANCHISE,
          variables: {
            input: this.franquicia,
          },
          update: (cache, { data: { updateFranchise } }) => {
            try {
              let data = cache.readQuery({
                query: FRANCHISES,
              });
              let findId = data.franchises
                .map((item) => item.id)
                .indexOf(updateFranchise.id);
              data.franchises.splice(findId, 1, updateFranchise);
              cache.writeQuery({
                query: FRANCHISES,
                data,
              });
            } catch {
              console.log();
            }
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Planta actualizada!',
            text: 'La planta fue correctamente actualizada.',
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Planta no actualizada',
            text: 'La planta no fue correctamente actualizada.',
          });
        });
    },
  },
};
</script>

<style></style>
