var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editar-franquicia"},[_c('vs-alert',{attrs:{"color":"danger","title":"Ally Not Found","active":_vm.franquiciaNotFound},on:{"update:active":function($event){_vm.franquiciaNotFound=$event}}},[_c('span',[_vm._v("Ally record with id: "+_vm._s(this.$route.params.franquiciaId.franquiciaId)+" not found. ")]),_c('span',[_c('span',[_vm._v("Check ")]),_c('router-link',{staticClass:"text-inherit underline",attrs:{"to":{ name: 'terceros-franquicias' }}},[_vm._v("All Franquicias")])],1)]),_c('vx-card',[(
        _vm.franquicia.attention_schedule &&
        _vm.franquicia.attention_schedule.length > 0
      )?_c('div',[_c('ValidationObserver',{ref:"validateFormFranchiseUpdate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Razón social"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Razón social","placeholder":"Razón social"},model:{value:(_vm.franquicia.name),callback:function ($$v) {_vm.$set(_vm.franquicia, "name", $$v)},expression:"franquicia.name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"numeric|required","name":"NIT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"NIT","placeholder":"NIT"},model:{value:(_vm.franquicia.nit),callback:function ($$v) {_vm.$set(_vm.franquicia, "nit", $$v)},expression:"franquicia.nit"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Contacto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Contacto","placeholder":"Contacto"},model:{value:(_vm.franquicia.contact_name),callback:function ($$v) {_vm.$set(_vm.franquicia, "contact_name", $$v)},expression:"franquicia.contact_name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"phone|required","name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Teléfono","placeholder":"Teléfono"},model:{value:(_vm.franquicia.phone),callback:function ($$v) {_vm.$set(_vm.franquicia, "phone", $$v)},expression:"franquicia.phone"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Tipo de negocio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Tipo de negocio","placeholder":"Tipo de negocio"},model:{value:(_vm.franquicia.commerce_type),callback:function ($$v) {_vm.$set(_vm.franquicia, "commerce_type", $$v)},expression:"franquicia.commerce_type"}},_vm._l((_vm.typeCommerce),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Dirección","placeholder":"Dirección"},model:{value:(_vm.franquicia.address),callback:function ($$v) {_vm.$set(_vm.franquicia, "address", $$v)},expression:"franquicia.address"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Horario de atención"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Horario de atención","multiple":""},model:{value:(_vm.franquicia.attention_schedule),callback:function ($$v) {_vm.$set(_vm.franquicia, "attention_schedule", $$v)},expression:"franquicia.attention_schedule"}},_vm._l((_vm.ListSchedules),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Medio de entrega"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Medio de entrega","multiple":""},model:{value:(_vm.franquicia.delivery_media),callback:function ($$v) {_vm.$set(_vm.franquicia, "delivery_media", $$v)},expression:"franquicia.delivery_media"}},_vm._l((_vm.vehicleTypes),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.name,"text":item.alias}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full flex justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-3",attrs:{"disabled":invalid},on:{"click":_vm.updateFranchise}},[_vm._v("Guardar")]),_c('vs-button',{staticClass:"mb-3",attrs:{"type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Descarcatar")])],1)])]}}],null,false,3651117565)})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }